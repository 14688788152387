export default {
  app_ame: "OpnForm",
  locale: "en",
  locales: { en: "EN" },
  githubAuth: null,
  notion: { worker: "https://notion-forms-worker.notionforms.workers.dev/v1" },
  links: {
    help_url: "https://help.opnform.com",
    github_url: "https://github.com/JhumanJ/OpnForm",
    github_forum_url: "https://github.com/JhumanJ/OpnForm/discussions",
    discord: "https://discord.gg/YTSjU2a9TS",
    twitter: "https://twitter.com/OpnForm",
    zapier_integration:
      "https://zapier.com/developer/public-invite/146950/58db583730cc46b821614468d94c35de/",
    book_onboarding: "https://zcal.co/i/YQVGEULQ",
    feature_requests: "https://feedback.opnform.com/",
    changelog_url: "https://feedback.opnform.com/changelog",
    roadmap: "https://feedback.opnform.com/roadmap",
    tech_docs: "https://docs.opnform.com",
  },
}
